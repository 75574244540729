import { RawLocation, Route } from 'vue-router';
import { RouteConfig } from 'vue-router/types/router';
import i18n from '@/i18n';
import RouterHelper from '@/router/middleware';
import LanguageViewer from '@/_components/language/language.vue';

const routers: RouteConfig[] = [
  {
    path: '/',
    redirect: `/${i18n.locale}`,
  },
  {
    path: '/:lang',
    name: 'index',
    component: LanguageViewer,
    redirect: { name: 'home' },
    children: [
      {
        path: '',
        name: 'home',
        redirect: { name: 'event-info', params: { eventId: '20380' }},
        meta: {
          showNavigation: false
        },
        component: (): Promise<object> => import('@/_components/about-landing/about-landing.vue'),
        children: [
          {
            path: 'forexhibitors',
            name: 'home-forexhibitors',
            meta: {
              showNavigation: false
            },
            component: (): Promise<object> => import('@/_components/about-landing/about-landing.vue'),
          },
        ]
      },
      {
        path: 'seat-plan',
        name: 'seat-plan',
        meta: {
          showNavigation: false,
        },
        component: (): Promise<object> => import('@/_components/seat-plan/seat-plan.vue'),
      },
      {
        path: 'auth',
        name: 'auth-page',
        component: (): Promise<object> => import('@/_components/auth/auth-page/auth-page.vue'),
        children: [
          {
            path: 'logout',
            name: 'auth-page-logout',
            component: (): Promise<object> => import('@/_components/auth/auth-page/auth-page.vue')
          },
        ],
      },
      {
        path: 'support',
        name: 'support',
        meta: {
          showNavigation: false,
        },
        component: (): Promise<object> => import('@/_components/support/support.vue'),
      },
      {
        path: 'unsubscribe/:eventId/:contactId/:emailString/:hashString',
        name: 'unsubscribe',
        meta: {
          showNavigation: false,
        },
        beforeEnter: RouterHelper.parseEventId,
        component: (): Promise<object> => import('@/_components/mail-settings/unsubscribe.vue'),
      },
      {
        path: 'events/:eventId/company/:companyExternalId',
        name: 'standalone-company',
        meta: {
          showNavigation: false,
        },
        beforeEnter: RouterHelper.parseEventId,
        component: (): Promise<object> => import('@/_modules/standalone-company/components/standalone-company-view/standalone-company-view.vue'),
      },
      {
        path: 'events/:eventId/contact/:contactId',
        name: 'standalone-contact',
        meta: {
          showNavigation: false,
        },
        beforeEnter: RouterHelper.parseEventId,
        component: (): Promise<object> => import('@/_modules/standalone-contact/components/standalone-contact-view/standalone-contact-view.vue'),
      },
      {
        path: 'events',
        component: (): Promise<object> => import('@/_modules/events/components/events/events.vue'),
        redirect: { name: 'event-list-my' },
        children: [
          {
            path: 'all-old',
            name: 'event-list',
            component: (): Promise<object> => import('@/_modules/events/components/event-list/event-list.vue'),
          },
          {
            path: '',
            name: 'event-list-my',
            component: (): Promise<object> => import('@/_modules/events/components/event-list/event-list.vue'),
            beforeEnter: RouterHelper.authorized,
          },
          {
            path: 'create',
            name: 'event-create',
            component: (): Promise<object> => import('@/_modules/events/components/create-event/create-event.vue'),
            beforeEnter: RouterHelper.authorized,
            alias: '/:lang/create-event',
          },
          {
            path: ':eventId',
            component: (): Promise<object> => import('@/_modules/events/components/event/event.vue'),
            beforeEnter: RouterHelper.parseEventId,
            children: [
              {
                path: '',
                name: 'event-info',
                component: (): Promise<object> => import('@/_modules/event-page/event-page-viewer/event-page-viewer.vue'),
              },
              {
                path: 'tickets',
                name: 'tickets-viewer',
                component: (): Promise<object> => import('@/_modules/tickets/components/tickets-viewer/tickets-viewer.vue'),
                beforeEnter: RouterHelper.authorized,
                children: [
                  {
                    path: '',
                    name: 'event-tickets',
                    component: (): Promise<object> => import('@/_modules/tickets/components/ticket-list/ticket-list.vue'),
                    beforeEnter: RouterHelper.authorized,
                  },
                  {
                    path: 'add',
                    name: 'create-ticket',
                    component: (): Promise<object> => import('@/_modules/tickets/components/create-ticket/create-ticket.vue'),
                    beforeEnter: RouterHelper.authorized,
                  },
                  {
                    path: 'ticket',
                    name: 'view-ticket-route',
                    component: (): Promise<object> => import('@/_modules/tickets/components/ticket-viewer/ticket-viewer.vue'),
                    children: [
                      {
                        path: '',
                        name: 'no-ticket-id',
                        redirect: { name: 'event-tickets' },
                      },
                      {
                        path: ':ticketId',
                        name: 'view-ticket',
                        component: (): Promise<object> => import('@/_modules/tickets/components/ticket-viewer/ticket-viewer.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                    ]
                  },
                ]
              },
              {
                path: 'result',
                name: 'result',
                component: (): Promise<object> => import('@/_modules/statistics/components/statistics/statistics.vue'),
                beforeEnter: RouterHelper.authorized,
                redirect: {
                  name: 'result-personal',
                },
                children: [
                  {
                    path: 'personal',
                    name: 'result-personal',
                    component: (): Promise<object> => import('@/_modules/statistics/components/personal/personal.vue'),
                    beforeEnter: RouterHelper.authorized,
                  },
                  {
                    path: 'company',
                    name: 'result-company',
                    component: (): Promise<object> => import('@/_modules/statistics/components/company-stat/company-stat.vue'),
                    beforeEnter: RouterHelper.authorized,
                  },
                  {
                    path: 'event',
                    name: 'result-event',
                    component: (): Promise<object> => import('@/_modules/statistics/components/event-stat/event-stat.vue'),
                    beforeEnter: RouterHelper.authorized,
                  },
                ],
              },
              {
                path: 'promo',
                name: '',
                redirect: (to: Route): RawLocation => ({ name: 'promo-live', params: { eventId: to.params.eventId } }),
                component: (): Promise<object> => import('@/views/EventPromo.vue'),
                beforeEnter: RouterHelper.authorized,
                children: [
                  {
                    path: '/:lang/events/:eventId/promo/live',
                    name: 'promo-live',
                    component: (): Promise<object> => import('@/_modules/promo/components/promo-live-new/promo-live-new.vue'),
                    beforeEnter: RouterHelper.authorized,
                  },
                  {
                    path: 'hall',
                    name: '',
                    component: (): Promise<object> => import('@/_modules/promo-hall/components/companies-viewer/companies-viewer.vue'),
                    beforeEnter: RouterHelper.authorized,
                    children: [
                      {
                        path: '',
                        name: 'promo-page-events-companies',
                        meta: {
                          isCompanyCategoriesChooserVisible: true,
                        },
                        component: (): Promise<object> => import('@/_modules/promo-hall/components/companies-list/companies-list.vue'),
                        beforeEnter: RouterHelper.authorized,
                        children: [
                          {
                            path: 'video-chat-invite/:inviteKey',
                            name: 'company-video-chat-invite',
                            component: (): Promise<object> => import('@/_modules/promo-hall/components/companies-list/companies-list.vue'),
                            beforeEnter: RouterHelper.authorized,
                          },
                          {
                            path: ':external_id',
                            name: 'promo-page-events-company',
                            meta: {
                              isCompanyCategoriesChooserVisible: true,
                            },
                            components: {
                              promoHallCompany: (): Promise<object> => import('@/_modules/promo-hall/components/opened-company-card/opened-company-card.vue'),
                            },
                            beforeEnter: RouterHelper.authorized,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'program',
                    name: 'promo-program',
                    component: (): Promise<object> => import('@/_modules/promo-program-new/components/program-viewer/program-viewer.vue'),
                    beforeEnter: RouterHelper.authorized,
                    meta: {
                      isProgramRoute: true
                    }
                  },
                  {
                    path: 'program/:programDate/:programId',
                    name: 'promo-program-page',
                    component: (): Promise<object> => import('@/_modules/promo-program-new/components/promo-program-page/promo-program-page.vue'),
                    beforeEnter: RouterHelper.authorized,
                    meta: {
                      isProgramRoute: true
                    }
                  },
                  {
                    path: 'program-old',
                    name: 'promo-program-old',
                    component: (): Promise<object> => import('@/_modules/promo-program/components/promo-program/promo-program.vue'),
                    beforeEnter: RouterHelper.authorized,
                    meta: {
                      isProgramRoute: true
                    }
                  },
                  {
                    path: 'program-old/:date',
                    name: 'promo-program-date',
                    component: (): Promise<object> => import('@/_modules/promo-program/components/promo-program/promo-program.vue'),
                    beforeEnter: RouterHelper.authorized,
                    meta: {
                      isProgramRoute: true
                    }
                  },
                  {
                    path: 'program-old/:date/:programId',
                    name: 'promo-program-date-program',
                    component: (): Promise<object> => import('@/_modules/promo-program/components/promo-program/promo-program.vue'),
                    beforeEnter: RouterHelper.authorized,
                    meta: {
                      isProgramRoute: true
                    }
                  },
                  {
                    path: 'program-old/:date/:programId/:contact_id',
                    name: 'promo-program-date-program-contact',
                    component: (): Promise<object> => import('@/_modules/promo-program/components/promo-program/promo-program.vue'),
                    beforeEnter: RouterHelper.authorized,
                    meta: {
                      isProgramRoute: true,
                      isScrollToTopNeeded: false,
                    }
                  },
                  {
                    path: 'contacts',
                    name: 'promo-contacts',
                    component: (): Promise<object> => import('@/_modules/contacts/contacts-viewer.vue'),
                    beforeEnter: RouterHelper.authorized,
                    meta: {
                      isContactUrlManagementNeeded: true,
                    },
                    children: [
                      {
                        path: ':contact_id',
                        name: 'promo-page-contacts-contact',
                        components: {
                          contactDetailsRouterView: (): Promise<object> => import('@/_modules/contacts/contacts-viewer.vue'),
                        },
                        beforeEnter: RouterHelper.authorized,
                        meta: {
                          isContactUrlManagementNeeded: true,
                          isScrollToTopNeeded: false,
                        },
                      },
                    ],
                  },
                  {
                    path: 'calendar/:contact_id?',
                    name: 'promo-page-calendar',
                    meta: {
                      isContactUrlManagementNeeded: true,
                      isScrollToTopNeeded: false,
                    },
                    component: (): Promise<object> => import('@/_modules/meetings/components/meetings/meetings.vue'),
                    beforeEnter: RouterHelper.authorized,
                    children: [
                      {
                        path: 'meeting-invite/:inviteKey',
                        name: 'meeting-invite',
                        component: (): Promise<object> => import('@/_modules/meetings/components/meetings/meetings.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                    ],
                  },
                  {
                    path: 'chats',
                    name: 'text-chats',
                    component: (): Promise<object> => import('@/_modules/text-chats/components/text-chats/text-chats.vue'),
                    beforeEnter: RouterHelper.authorized,
                    children: [
                      {
                        path: ':textChatId/:contact_id?',
                        name: 'text-chat-expanded',
                        components: {
                          textChatExpanded: (): Promise<object> => import('@/_modules/text-chats/components/text-chat-expanded/text-chat-expanded.vue'),
                        },
                        beforeEnter: RouterHelper.authorized,
                      },
                    ],
                  },
                  {
                    path: 'news',
                    name: 'news',
                    component: (): Promise<object> => import('@/_modules/news/components/news/news.vue'),
                    beforeEnter: RouterHelper.authorized,
                    children: [
                      {
                        path: ':newsIssueId',
                        name: 'news-issue',
                        components: {
                          newsIssue: (): Promise<object> => import('@/_modules/news/components/news-issue/news-issue.vue'),
                        },
                        beforeEnter: RouterHelper.authorized,
                      },
                    ],
                  },
                  {
                    path: 'notes',
                    name: 'notes',
                    component: (): Promise<object> => import('@/_modules/notes/components/notes/notes.vue'),
                    beforeEnter: RouterHelper.authorized,
                    children: [
                      // {
                      //   path: ':newsIssueId',
                      //   name: 'news-issue',
                      //   components: {
                      //     newsIssue: (): Promise<object> => import('@/_modules/news/components/news-issue/news-issue.vue'),
                      //   },
                      //   beforeEnter: RouterHelper.authorized,
                      // },
                    ],
                  },
                  {
                    path: 'edit',
                    name: 'promo-page-edit',
                    redirect: {name: 'promo-page-edit-contact'},
                    component: (): Promise<object> => import('@/_modules/promo/components/edit/edit.vue'),
                    beforeEnter: RouterHelper.authorized,
                    children: [
                      {
                        path: 'contact',
                        name: 'promo-page-edit-contact',
                        component: (): Promise<object> => import('@/_modules/promo/components/edit/edit.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'company',
                        name: 'promo-page-edit-company',
                        component: (): Promise<object> => import('@/_modules/promo/components/edit/edit.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                    ]
                  },
                  {
                    path: 'cabinet',
                    name: 'promo-page-cabinet',
                    redirect: {name: 'promo-page-cabinet-event-settings'},
                    component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet/cabinet.vue'),
                    beforeEnter: RouterHelper.authorized,
                    children: [
                      {
                        path: 'main-page',
                        name: 'promo-page-cabinet-main-page',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-main-page/cabinet-main-page.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'lobby',
                        name: 'promo-page-cabinet-lobby',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-lobby/cabinet-lobby.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'exponents',
                        name: 'promo-page-cabinet-exponents',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-exponents/cabinet-exponents.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'program',
                        name: 'promo-page-cabinet-program',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-program/cabinet-program.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'map',
                        name: 'promo-page-cabinet-event-map',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-event-map/cabinet-event-map.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'contacts',
                        name: 'promo-page-cabinet-contacts',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-contacts/cabinet-contacts.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'meetings',
                        name: 'promo-page-cabinet-meetings',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-meetings/cabinet-meetings.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'chats',
                        name: 'promo-page-cabinet-chats',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-chats/cabinet-chats.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'news',
                        name: 'cabinet-news',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-news/cabinet-news.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'event-settings',
                        name: 'promo-page-cabinet-event-settings',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-event-settings/cabinet-event-settings.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'program/create',
                        name: 'promo-page-cabinet-program-create',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-program-form/cabinet-program-form.vue'),
                        beforeEnter: RouterHelper.authorized,
                        meta: {
                          hideCabinetNavigation: true,
                        },
                      },
                      {
                        path: 'program/edit',
                        name: 'promo-page-cabinet-program-edit',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-program-form/cabinet-program-form.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'program/:date',
                        name: 'promo-page-cabinet-program-date',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-program/cabinet-program.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'program/:date/create',
                        name: 'promo-page-cabinet-program-date-create',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-program-form/cabinet-program-form.vue'),
                        beforeEnter: RouterHelper.authorized,
                        meta: {
                          hideCabinetNavigation: true,
                        },
                      },
                      {
                        path: 'program/:date/edit',
                        name: 'promo-page-cabinet-program-date-edit',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-program-form/cabinet-program-form.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                    ]
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'use-cases',
        name: 'use-cases-redirector',
        component: (): Promise<object> => import('@/_modules/page-not-found/page-not-found.vue'),
        beforeEnter: (to) => {
          // See AW-3840
          window.location.href = window.location.origin + '/' + to.params.lang + '/use-cases/';
          return false;
        }
      },
      {
        path: '*',
        name: '*',
        component: (): Promise<object> => import('@/_modules/page-not-found/page-not-found.vue'),
      },
    ],
  },
];

export default routers;
